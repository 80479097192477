import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Quote Tool" />
    <div className="container service-info-window mg-90">
      <h2>Quote Tool!</h2>
    </div>
  </Layout>
)

export default NotFoundPage
